<template>
  <div class="g-sidenav-show bg-gray-100">
    <div class="min-height-300 bg-gradient-dark position-absolute w-100"></div>
    <aside
      class="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 "
      id="sidenav-main">
      <div class="sidenav-header">
        <i class="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
          aria-hidden="true" id="iconSidenav"></i>
        <a class="navbar-brand m-0">
          <span class="ms-1 font-weight-bold">STMS </span>
        </a>
      </div>
      <hr class="horizontal dark mt-0">
      <div class="collapse navbar-collapse w-auto h-auto ps" id="sidenav-collapse-main">

          <ul class="navbar-nav" v-for="menu in sideMenus" v-bind:key="menu.name">
            <li class="nav-item"
              v-if="'children' in menu && menu.children.length > 0 && userPermissions.some(el => menu.roles.includes(el))">
              <a data-bs-toggle="collapse" @click="toggleCollapse" class="nav-link collapsed"
                v-bind:aria-controls="menu.name.replace(/ /g, '')" role="button" aria-expanded="false">
                <span :class="menu.icon"> </span>
                <span class="nav-link-text ms-1">{{ menu.name }}</span>
              </a>
              <div class="collapse" v-bind:id="menu.name.replace(/ /g, '')">
                  <ul class="nav ms-2" v-for="c_menu in menu.children" v-bind:key="c_menu.name">
                    <li class="nav-item" v-if="userPermissions.some(el => c_menu.roles.includes(el))">
                      <router-link :to="c_menu.to" class="nav-link ">
                        <span :class="c_menu.icon"> </span>
                        <span class="m-2 sidenav-normal">{{ c_menu.name }}</span>
                      </router-link>
                    </li>
                  </ul>
              </div>
            </li>
            <li class="nav-item" v-else-if="userPermissions.some(el => menu.roles.includes(el))">
              <router-link :to="menu.to" class="nav-link ">
                <span :class="menu.icon"> </span>
                <span class="m-2 sidenav-normal">{{ menu.name }}</span>
              </router-link>
            </li>
          </ul>
      </div>

    </aside>
    <main class="main-content position-relative border-radius-lg ">
      <nav class="navbar navbar-main navbar-expand-lg  px-0 mx-4 shadow-none border-radius-xl z-index-sticky "
        id="navbarBlur" data-scroll="false">
        <div class="container-fluid py-1 px-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0">
              <li class="breadcrumb-item text-sm text-white active" aria-current="page">
                <a class="text-white opacity-9" href="javascript:;" @click="$router.go(-1)">
                  <i class="fas fa-2x fa-arrow-circle-left"></i>
                </a>
              </li>
            </ol>
          </nav>
          <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none mx-3">
            <a href="javascript:void(0)" class="nav-link p-0">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </div>
          <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
            <div class="ms-md-auto pe-md-4 d-flex align-items-center">

            </div>
            <ul class="navbar-nav  justify-content-end">

              <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
                <a href="javascript:;" class="nav-link text-white p-0" id="iconNavbarSidenav">
                  <div class="sidenav-toggler-inner">
                    <i class="sidenav-toggler-line bg-white"></i>
                    <i class="sidenav-toggler-line bg-white"></i>
                    <i class="sidenav-toggler-line bg-white"></i>
                  </div>
                </a>
              </li>

              <li class="nav-item dropdown d-flex align-items-center">
                <a href="javascript:;" class="nav-link badge-circle badge-lg bg-primary p-2 text-white show"
                  id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="true">
                  <i class="fa fa-user cursor-pointer mx-1" aria-hidden="true"></i>
                </a>
                <span class="text-white mx-1 text-capitalize cursor-pointer" data-bs-toggle="dropdown">{{ username
                  }}</span>
                <ul class="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton"
                  data-bs-popper="static">
                  <li class="mb-2">
                    <router-link to="/account" class="dropdown-item border-radius-md nav-link">
                      <i class="fas fa-user-alt"></i>
                      <span class="mx-1"></span>
                      My Account
                    </router-link>
                  </li>
                  <li class="mb-2" @click="logout">
                    <a class="dropdown-item border-radius-md nav-link" href="javascript:;">
                      <i class="fas fa-sign-out-alt"></i>
                      <span class="mx-1"></span>
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="container-fluid py-4 mt-7">
        <router-view v-slot="{ Component }">
          <transition name="slide-fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </main>
  </div>
</template>

<script>
import { sideMenus } from '@/guards/sidebarMenu';
// import { routeHistory } from '@/router/routerHistory';
import { Collapse } from "bootstrap";
import BsCollapse from '@/components/Collapse.vue'
import Swal from 'sweetalert2';


export default {
  name: "DashboardLayout",
  components: {
    BsCollapse
  },
  data() {
    return {
      user: this.$store.state.auth.userPermissions,
      sideMenus: sideMenus,
      collapseInstances: [
      ],
    }
  },
  computed: {
    userPermissions() {
      return this.$store.state.auth?.userPermissions || [];
    },
    username() {
      return this.$store.state.auth?.userProfile?.firstName || '';
    },
    loadingStatus() {
      return this.$store.state.loading;
    }
  },
  watch: {
    loadingStatus(newValue) {
      if (newValue) {
        this.showLoadingStatus();
      } else {
        Swal.close();
      }
    }
  },
  methods: {
    // goBack() {
    //     if (this.previousRouteName) {
    //       this.$router.go(-1);
    //     } else {
    //       // Handle the case where there is no history (e.g., direct navigation to the page)
    //       this.$router.push({ name: 'Home' });
    //     }
    //   },

    logout: function () {
      this.$store.dispatch('logout');
    },
    toggleCollapse() {
      if (this.collapseInstance) {
        console.log('toggle');
        this.collapseInstance.toggle(); // Toggle the stored Collapse instance
      }
    },
    showLoadingStatus() {
      this.$alert.loading();
    },

  },

  created() {

  },

  mounted() {
    console.log('mounted');
    this.$nextTick(() => { // Use this.$nextTick instead of nextTick directly
      console.log('nextTick');
      // Direct DOM manipulations here, but remember Vue doesn't recommend manipulating DOM directly like this
      // const trigger = document.querySelector(`[data-bs-toggle="collapse"]`); // Example selector, adjust as needed
      const target = document.getElementById("InventoryManagement");

      if (target) {
        console.log('collapse');
        // target.classList.add('collapse');
        this.collapseInstance = new Collapse(target, { toggle: this.toggle });
      }
    });
  }


}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
